import ApiService, { HTTPMethod } from "./api.core";
import { Transformer } from "../models/Transformer";
import { isToday, getAiWithoutId, equivalentAdditionalInsured, removeDuplicateAdditionalInsured, isTodayBreakdown } from "../services/utils";
import { FirebaseService, FirebaseConfigKey } from "../services/FirebaseService";
import { COUNTRIES } from "../Constants";
import { SessionService } from "../services/SessionService";
import { COVERAGE_TYPES } from "../models/insurance-models/CoverageType";
import { WorldWideOption } from "../models/insurance-models/WorldWideOption";


class InsuranceApi {

    async loadOffers(subscriptionStartDate: Date, maximumUasInAir = 1, coverageType = COVERAGE_TYPES.COMBINED, drones=[],
         equipment=[], operators=[], personalInjuryCoverage = 0, medicalExpense = 0,
          worldWideCoverage = {territorialLimits: WorldWideOption.Default, territorialLimitsDescription: ''},
          firstGuidance3rdParty = false,
          passengersCoverage = {firstGuidance: false, liability: 0},
          airCrewCoverage = {firstGuidance: false, liability: 0},
           specialPrice = null ) {

        let body = (!subscriptionStartDate || isTodayBreakdown(subscriptionStartDate.getUTCDate(), subscriptionStartDate.getUTCMonth(), subscriptionStartDate.getUTCFullYear())) ? {} : {
            "start_time": subscriptionStartDate.getTime()
        };

        if(specialPrice != null) {
            body.custom_premium = specialPrice.custom_premium
            body.custom_premium_token = specialPrice.custom_premium_token
        }

        if(FirebaseService.getValue(FirebaseConfigKey.COUNTRY) == COUNTRIES.UK) {
            body.maximum_uas_in_air = maximumUasInAir 
            body.coverage_type = coverageType
            body.operators = operators
        }

        if(!SessionService.isLoggedIn()) {
            body.drones = drones
            body.equipment = equipment
            body.operators = operators
        }

        body.medical_expense_limit = medicalExpense
        body.personal_injury_limit = personalInjuryCoverage
        body.territorial_limits_description = worldWideCoverage.territorialLimitsDescription
        body.territorial_limits = worldWideCoverage.territorialLimits
        body.passengers_liability_limit = passengersCoverage.liability
        // body.passengers_first_guidance = passengersCoverage.firstGuidance
        body.passengers_first_guidance = firstGuidance3rdParty
        body.air_crew_liability_limit = airCrewCoverage.liability
        // body.air_crew_first_guidance = airCrewCoverage.firstGuidance
        body.air_crew_first_guidance = firstGuidance3rdParty
        body.first_guidance = firstGuidance3rdParty

        let res = await ApiService.fetch("subscriptions/offers", HTTPMethod.POST, body);

        if (res.ok) {  
            res.monthlyOffers = Transformer.parseOffers(res.data["offers"])
            res.annualOffers = Transformer.parseOffers(res.data["annual_offers"]);
        }
        return res; 
    }

    async submitAviationForm(forminformation) {
        let res = await ApiService.fetch('aviationformsubmit', HTTPMethod.POST, forminformation)
        return res
    }

    async getActiveInsurances() {
        let res = await ApiService.fetch("insurances/me/active");
        if (res.ok && res.data && res.data.length > 0) {
            let activeInsurance = Transformer.parseActiveInsuranceModel(res.data[0])
            res.parsedData = activeInsurance;
        }
        return res;
    }

    async hasInsurance() {
        let res = await ApiService.fetch("insurances/me");
        return res.ok && res.data && res.data.length > 0
    }

    async purchaseInsurance(optionId, additionalInsured, autoRenew, externalActorId, paymentMethod, installments) {
        let body = {}; 
        body.offer_id = optionId;
        body.auto_renew = !!autoRenew;
        body.payment_method = paymentMethod
        body.installments = installments
        
        if(externalActorId) {
            body.external_actor_id = externalActorId
        }

        if (additionalInsured) {
          body.additional_insureds = removeDuplicateAdditionalInsured(additionalInsured.map(q => getAiWithoutId(q)));
        }
        
        let res = await ApiService.fetch("insurances/", HTTPMethod.POST, body);
        
        if (res.ok) {
          let activeInsurance = Transformer.parseActiveInsuranceModel(res.data)
          res.parsedData = activeInsurance;
        }
        return res;
    }

    async sendAQuote(insurancePurchaseContext, offersContext, emails, companyName, ) {
        let body = {
            liability: insurancePurchaseContext.selectedOption.liabilityLimit,
            auto_renew: !!insurancePurchaseContext.autoRenew,
            additional_insureds: removeDuplicateAdditionalInsured(insurancePurchaseContext.additionalInsuredList.map(q => getAiWithoutId(q))),
            emails: emails, 
            name: companyName, 
            drones: insurancePurchaseContext.drones || [],
            equipment: insurancePurchaseContext.equipment || [],
            billing_period: insurancePurchaseContext.selectedOption.billingPeriod,
            coverage_type: offersContext.coverageType,
            operators: offersContext.operators,
            maximum_uas_in_air: offersContext.maximumUasInAir,
            territorial_limits: offersContext.worldWideCoverage.territorialLimits,
            territorial_limits_description: offersContext.worldWideCoverage.territorialLimitsDescription,
            personal_injury_limit: offersContext.personalInjuryCoverage,
            medical_expense_limit: offersContext.medicalExpense,
            custom_premium: offersContext.specialPrice.custom_premium,
            custom_premium_token: offersContext.specialPrice.custom_premium_token,
            passengers_liability_limit: offersContext.passengersCoverage.liability,
            // passengers_first_guidance: offersContext.passengersCoverage.firstGuidance,
            passengers_first_guidance: offersContext.firstGuidance,
            air_crew_liability_limit: offersContext.airCrewCoverage.liability,
            //air_crew_first_guidance: offersContext.airCrewCoverage.firstGuidance,
            air_crew_first_guidance: offersContext.firstGuidance,
            first_guidance: offersContext.firstGuidance,
            
        }
        if (offersContext.subscriptionStartTime && !isToday(offersContext.subscriptionStartTime)) {
            body["start_time"] = offersContext.subscriptionStartTime.getTime()
        }
        return await ApiService.fetch('insurances/quote', HTTPMethod.POST, body)
    }

    async saveQuoteState(insurancePurchaseContext, offersContext) {
        let body = {
            liability: insurancePurchaseContext.selectedOption.liabilityLimit,
            auto_renew: !!insurancePurchaseContext.autoRenew,
            additional_insureds: removeDuplicateAdditionalInsured(insurancePurchaseContext.additionalInsuredList.map(q => getAiWithoutId(q))),
            drones: insurancePurchaseContext.drones || [],
            equipment: insurancePurchaseContext.equipment || [],
            billing_period: insurancePurchaseContext.selectedOption.billingPeriod,
            coverage_type: offersContext.coverageType,
            operators: offersContext.operators,
            maximum_uas_in_air: offersContext.maximumUasInAir,
            territorial_limits: offersContext.worldWideCoverage.territorialLimits,
            territorial_limits_description: offersContext.worldWideCoverage.territorialLimitsDescription,
            personal_injury_limit: offersContext.personalInjuryCoverage,
            medical_expense_limit: offersContext.medicalExpense,
            custom_premium: offersContext.specialPrice.custom_premium,
            custom_premium_token: offersContext.specialPrice.custom_premium_token,
            passengers_liability_limit: offersContext.passengersCoverage.liability,
            // passengers_first_guidance: offersContext.passengersCoverage.firstGuidance,
            passengers_first_guidance: offersContext.firstGuidance,
            air_crew_liability_limit: offersContext.airCrewCoverage.liability,
            //air_crew_first_guidance: offersContext.airCrewCoverage.firstGuidance,
            air_crew_first_guidance: offersContext.firstGuidance,
            first_guidance: offersContext.firstGuidance,
        }
        if (offersContext.subscriptionStartTime && !isToday(offersContext.subscriptionStartTime)) {
            body["start_time"] = offersContext.subscriptionStartTime.getTime()
        }
        return await ApiService.fetch('insurances/purchaseState', HTTPMethod.POST, body)
    }

    async referQuote(insurancePurchaseContext, offersContext) {
        let body = {
            liability: insurancePurchaseContext.selectedOption.liabilityLimit,
            auto_renew: !!insurancePurchaseContext.autoRenew,
            additional_insureds: removeDuplicateAdditionalInsured(insurancePurchaseContext.additionalInsuredList.map(q => getAiWithoutId(q))),
            drones: insurancePurchaseContext.drones || [],
            equipment: insurancePurchaseContext.equipment || [],
            billing_period: insurancePurchaseContext.selectedOption.billingPeriod,
            coverage_type: offersContext.coverageType,
            operators: offersContext.operators,
            maximum_uas_in_air: offersContext.maximumUasInAir,
            territorial_limits: offersContext.worldWideCoverage.territorialLimits,
            territorial_limits_description: offersContext.worldWideCoverage.territorialLimitsDescription,
            personal_injury_limit: offersContext.personalInjuryCoverage,
            medical_expense_limit: offersContext.medicalExpense,
            custom_premium: offersContext.specialPrice.custom_premium,
            custom_premium_token: offersContext.specialPrice.custom_premium_token,
            passengers_liability_limit: offersContext.passengersCoverage.liability,
            // passengers_first_guidance: offersContext.passengersCoverage.firstGuidance,
            passengers_first_guidance: offersContext.firstGuidance,
            air_crew_liability_limit: offersContext.airCrewCoverage.liability,
            //air_crew_first_guidance: offersContext.airCrewCoverage.firstGuidance,
            air_crew_first_guidance: offersContext.firstGuidance,
            first_guidance: offersContext.firstGuidance,
        }
        if (offersContext.subscriptionStartTime && !isToday(offersContext.subscriptionStartTime)) {
            body["start_time"] = offersContext.subscriptionStartTime.getTime()
        }
        return await ApiService.fetch('insurances/refer-quote', HTTPMethod.POST, body)
    }
    
}

export default new InsuranceApi()