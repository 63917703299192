
import { createMuiTheme } from '@material-ui/core/styles';
import BrokerConfigService from '../services/BrokerConfigService';

export function createSkywatchMaterialTheme(theme) {
  return createMuiTheme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: theme.blue,
        contrastText: '#fff',
        
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        main: theme.secondary,
        // dark: will be calculated from palette.secondary.main,
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
    typography: {
      fontFamily: [
          'Nunito Sans',
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(',')
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
            '&$focused $notchedOutline': {
                borderColor: theme.blue,
                borderWidth: 1,
            },
        },
      },
      MuiFormLabel: {
        root: {
            '&$focused': {
                color: theme.blue,
            }
        }
      },
      MuiInputBase: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },
  });
}