import React from 'react'
import './InsurerFooter.css'
import { isMobile, BrowserView } from 'react-device-detect'
import { useHistory, useLocation } from 'react-router-dom'
import { Experiment, Variant } from "react-optimize";

export const InsurerFooter = () => {

    const location = useLocation()
    const history = useHistory()

    const privacyHref = history.createHref({pathname: '/privacy-policy'})
    const termsHref = history.createHref({pathname: '/terms-and-conditions'})

    if(location.pathname == '/ilsa' || location.pathname == '/login' || location.pathname == '/brokerLogin' || location.pathname == '/logout') return <div />

    return (<>
        <br />
        <div className="text-tiny gray footer-text">
            <div className="margin-top-3">Insurance is provided by Aerospace Insurance Brokers on behalf of the Phoenix insurance. The technology platform is managed by a 3rd party, SkyWatch LTD, data inserted on this platform is shared with SkyWatch for the purpose of providing insurance, and only for this purpose - check out our 
            full&nbsp;
                 <a rel="noopener noreferrer" 
                    target="_blank"
                    href={termsHref}
                    className="blue underline clickable">Terms and Conditions</a> and <a rel="noopener noreferrer"
                        target="_blank"
                        href={privacyHref}
                        className="blue underline clickable">Privacy Policy</a>
            </div>
            {isMobile && location.pathname.startsWith('/insurance') && <div style={{height: 80}}></div> }
            <BrowserView>
                <Experiment id="1QGQmMd7QridvBt7dm40KA">
                    <Variant id="1">
                        <div style={{height: 90}}></div>
                    </Variant>
                </Experiment>
            </BrowserView>
        </div>
    </>)
}