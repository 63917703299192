import React, { useReducer } from "react"
import ownersApi from "../api/owners.api";
import { Action } from "../models/Action";
import { EventEmitterService, EventKey } from "../services/EventEmitterService";
import { SessionService } from "../services/SessionService";

export const ACTIONS = {
    PROFILE_SAVE: "PROFILE_SAVE",
    SAVE_ADDRESS: 'SAVE_ADDRESS',
    SAVE_USER_INFO: 'SAVE_USER_INFO',
    SAVE_INSURANCE_HISTORY: 'SAVE_INSURANCE_HISTORY',
}

const defaultState = {
    dispatch: (action: Action) => {},
    owners: [],
    allOwners: [],
    address: {},
    user_info: {},
    insurance_history: {},
    address_saved: false,
    user_info_saved: false,
    insurance_history_saved: false,
}

const reducer = (state, action: Action) => {
    let newState = state
    switch (action.type) {
        case ACTIONS.SAVE_ADDRESS:
            newState = {...state, address: action.data, address_saved: true}
            break
        case ACTIONS.SAVE_USER_INFO:
            newState = {...state, user_info: action.data, user_info_saved: true}
            break
        case ACTIONS.SAVE_INSURANCE_HISTORY:
            newState = {...state, insurance_history: action.data, insurance_history_saved: true}
            break
        case 'LOAD_ADDITIONAL_OWNERS':
            newState = {...state, owners: action.data, allOwners: action.data}
            break
        case 'ACTIVE_OWNERS_UPDATED':
            newState = {...state, owners: action.data, owners: action.data}
            break
        case 'TOTAL_OWNERS_UPDATED':
            newState = {...state, owners: action.data, allOwners: action.data}
            break    
        case 'RESET':
            newState = {...state, insurance_history_saved: false, user_info_saved: false, address_saved: false}
            break
      default: 
        newState = state;
        break
    }
    
    return newState
};


export const UserProfileContextStore = React.createContext(defaultState)

const UserProfileContext = (props) => {
    const [state, dispatch] = useReducer(reducer, defaultState)


    const loadAdditionalOwners = async () => {
        let res = await ownersApi.getAdditionalOwners();
        if (res.ok) {
            dispatch(new Action('LOAD_ADDITIONAL_OWNERS', res.data));
        }
    }

    React.useEffect(() => {
        SessionService.isLoggedIn() && loadAdditionalOwners()

        let id3 = EventEmitterService.subscribe(EventKey.USER_LOGGED_IN, async () => {
            loadAdditionalOwners()
        })

        return function cleanup () {
            EventEmitterService.unsubscribe(EventKey.USER_LOGGED_IN, id3)
        }
    }, [])

    return (<UserProfileContextStore.Provider value={{ ...state, dispatch }}>
            {props.children}
        </UserProfileContextStore.Provider>)   
}

export default UserProfileContext



