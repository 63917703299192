import ApiService, { HTTPMethod } from "./api.core";
import { buildQueryParamsUrl } from '../services/utils';
import UserProfileModel from "../models/user-models/UserProfileModel";

class OwnersApi {

    async addAdditionalOwner(owner) {
      owner = Object.assign({}, owner, {name: UserProfileModel.fullNameCreator(owner.first_name, owner.last_name)})
      return ApiService.fetch('users/me/additional_owners', HTTPMethod.POST, owner)
    }

    async editAdditionalOwner(owner) {
      owner = Object.assign({}, owner, {name: UserProfileModel.fullNameCreator(owner.first_name, owner.last_name)})
      return ApiService.fetch('users/me/additional_owners', HTTPMethod.PUT, owner)
    }

    async deleteAdditionalOwner(owner) {
      owner = Object.assign({}, owner, {name: UserProfileModel.fullNameCreator(owner.first_name, owner.last_name)})
      return ApiService.fetch('users/me/additional_owners?additionalOwnerId=' + owner.id, HTTPMethod.DELETE, owner)
    }

    async getAdditionalOwners() {
      let res = await ApiService.fetch('users/me/additional_owners', HTTPMethod.GET)
      if(res.ok && res.data) {
        res.data = res.data.map(ai => {
          let splittedName = UserProfileModel.fullNameSplitter(ai.name);
          return Object.assign(ai, {first_name: splittedName[0], last_name: splittedName[1], name: ai.name});
        })
      }
      return res
    }

}

export default new OwnersApi()