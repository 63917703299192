import React, { useReducer, useEffect } from "react"
import InsuranceApi from "../api/insurance.api";
import { EventEmitterService, EventKey } from "../services/EventEmitterService";
import { SessionService } from "../services/SessionService";
import { isToday, isUK } from "../services/utils";
import StorageService from "../services/StorageService";
import { ONGOING_PURCHASE } from "./InsuracePurchaseContext";
import { COVERAGE_TYPES } from "../models/insurance-models/CoverageType";
import { FirebaseService, FirebaseConfigKey } from "../services/FirebaseService";
import { WorldWideOption } from "../models/insurance-models/WorldWideOption";
import dayjs from "dayjs";
import { NEW_STARTING_DATE } from "../Constants";

export const OFFERS_HISTORY_KEY = 'offers_ongoing_purchase'

export const OFFERS_ACTIOS = {
    ADD_OPERATOR: 'ADD_OPERATOR',
    DELETE_OPERATOR: 'DELETE_OPERATOR',
    EDIT_OPERATOR: 'EDIT_OPERATOR',
    SET_SPECIAL_PRICE: 'SET_SPECIAL_PRICE',
}

const defaultState = {
    maximumUasInAir: 1,
    monthlyOffers: [],
    annualOffers: [],
    operators: [],
    worldWideCoverage: { territorialLimits: WorldWideOption.Default, territorialLimitsDescription: '' },
    personalInjuryCoverage: FirebaseService.getArray(FirebaseConfigKey.PERSONAL_INJURY_COVERAGE)[0],
    medicalExpense: FirebaseService.getArray(FirebaseConfigKey.MEDICAL_EXPENSES)[0],
    airCrewCoverage: { firstGuidance: false, liability: 0 },
    passengersCoverage: { firstGuidance: false, liability: 0 },
    coverageType: isUK() ? COVERAGE_TYPES.LIABILITY : COVERAGE_TYPES.COMBINED,
    subscriptionStartTime:  dayjs(NEW_STARTING_DATE).isAfter(dayjs(new Date())) ? NEW_STARTING_DATE : new Date(),
    specialPrice: { enabled: "no", custom_premium: 0, custom_premium_token: '' },
    firstGuidance: false,
    changeStartingDate: (date) => {throw "Not Implemented Exception"},
    changeMaximumUAS: (maximumUasInAir) => {throw "Not Implemented Exception"},
    changeCoverageType: (type) => {throw "Not implemented"},
    changePersonalInjury: (value) => {throw "Not implemented"},
    changeMedicalExpenseLimit: (value) => {throw "Not implemented"},
    loadQuote: (quote) => {throw "Not implemented"},
    flipWorldWideCoverage: (description) => {throw 'Not implemented'},
}

const calculateState = (state, action) => {
    switch (action.type) {
        case 'RESET': 
            return defaultState
        case 'MAXIMUM_UAS_CHANGE':
            return {...state, maximumUasInAir: action.maximumUasInAir}
        case 'OFFERS_LOADED':
            return {...state, monthlyOffers: action.monthlyOffers, annualOffers: action.annualOffers}
        case 'PERSONAL_INJURY_CHANGE':
            return {...state, personalInjuryCoverage: Number.parseInt(action.value)}
        case 'MEDICAL_EXPENSE_LIMIT_CHANGE':
            return {...state, medicalExpense: Number.parseInt(action.value)}
        case 'CHANGE_PASSENGERS_COVERAGE': 
            return {...state, passengersCoverage: action.data}
        case 'CHANGE_AIRCREW_COVERAGE':  
            return {...state, airCrewCoverage: action.data}
        case 'SET_WORLD_WIDE_COVERAGE_LIMITS':
            return {...state, worldWideCoverage: { territorialLimits: action.data.limits,
                 territorialLimitsDescription: action.data.desc }}
        case '3RD_FIRST_GUIDANCE': 
            return { ...state, firstGuidance: action.data}
        case 'CHANGE_STARTING_DATE':
            if (isToday(action.startingDate)){
                return {...state, subscriptionStartTime: new Date()}
            }
            else if(action.startingDate && action.startingDate > new Date() && !isToday(action.startingDate))
                return {...state, subscriptionStartTime: action.startingDate}
            else
                return state
        case 'LOAD_QUOTE':
            let startingDate = new Date()
            if(action.data.start_time) {
                let requestedStartingTime = new Date(action.data.start_time)
                if(requestedStartingTime > new Date() && !isToday(requestedStartingTime)) 
                    startingDate = requestedStartingTime
            } 
            return {...state, 
                    subscriptionStartTime: startingDate,
                     maximumUasInAir: action.data.maximumUasInAir || action.data.maximum_uas_in_air || 1, 
                     coverageType: action.data.coverage_type || action.data.coverageType || COVERAGE_TYPES.COMBINED,
                     operators: action.data.operators || action.data.operators || [],
                     worldWideCoverage: { territorialLimits: action.data.territorial_limits, territorialLimitsDescription: action.data.territorial_limits_description },
                     personalInjuryCoverage: Number.parseInt(action.data.personal_injury_limit),
                     medicalExpense: Number.parseInt(action.data.medical_expense_limit),
                     customPremium: Number.parseInt(action.data.custom_premium),
                     customPremiumToken: action.data.custom_premium_token
                    }
        case 'SET_COVERAGE_TYPE':
            return {...state, coverageType: action.data}
        case OFFERS_ACTIOS.SET_SPECIAL_PRICE: 
            return {...state, specialPrice: action.data}
        case OFFERS_ACTIOS.ADD_OPERATOR:
                return {...state, operators: [...state.operators, action.data]}
        case OFFERS_ACTIOS.EDIT_OPERATOR:
            return {...state, operators: state.operators.map((item, index) => index === action.data.index ? action.data.operator : item)}
        case OFFERS_ACTIOS.DELETE_OPERATOR: 
            return {...state, operators: state.operators.filter((item, index) => index != action.data)}
      default: 
        return state;
    }
}

const stateToLocalStorage = (state) => {
    let savedStatte = Object.assign({}, state)
    delete savedStatte.monthlyOffers
    delete savedStatte.annualOffers
    StorageService.setItem(OFFERS_HISTORY_KEY, JSON.stringify(savedStatte))
}

const reducer = (state, action) => {
    console.log('BuyingInsuranceContext state', state, action)
    let newState = calculateState(state, action)
    stateToLocalStorage(newState)
    return newState
};


export const BuyingInsuranceContextStore = React.createContext(defaultState)

const BuyingInsuranceContext = (props) => {
    const [state, dispatch] = useReducer(reducer, defaultState, () => {
        let savedState = {}
        // Initialize from local storage
        if(StorageService.getItem(OFFERS_HISTORY_KEY)) {
            savedState = JSON.parse(StorageService.getItem(OFFERS_HISTORY_KEY))
            savedState.subscriptionStartTime = new Date(savedState.subscriptionStartTime)
            if(savedState.subscriptionStartTime < new Date() || !savedState.subscriptionStartTime) {
                savedState.subscriptionStartTime = new Date()
            }
            if(savedState.subscriptionStartTime < NEW_STARTING_DATE)
                savedState.subscriptionStartTime = NEW_STARTING_DATE
            if(!parseInt(savedState.maximumUasInAir))
                savedState.maximumUasInAir = 1
        }

        return Object.assign({}, defaultState, savedState)
    })

    const loadOffers = async () => {
        let startTime = state.subscriptionStartTime
        if(startTime && isToday(startTime))
            startTime = undefined
        else{
            let dateObj = new Date(startTime);
            startTime = new Date(Date.UTC(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate(), 0, 1, 0, 0));
        }

        let res = undefined
        if(SessionService.isLoggedIn()) {
            if(SessionService.isAdmin() && state.specialPrice.enabled == 'yes')
                res = await InsuranceApi.loadOffers(startTime, state.maximumUasInAir, state.coverageType, [], [],
                    state.operators, state.personalInjuryCoverage, state.medicalExpense, state.worldWideCoverage,
                    state.firstGuidance, state.passengersCoverage, state.airCrewCoverage, state.specialPrice)
            else
                res = await InsuranceApi.loadOffers(startTime, state.maximumUasInAir, state.coverageType, [], [],
                     state.operators, state.personalInjuryCoverage, state.medicalExpense, state.worldWideCoverage,
                     state.firstGuidance, state.passengersCoverage, state.airCrewCoverage)
        }
        else {
            let drones = []
            let equipment = []
            let savedState = JSON.parse(StorageService.getItem(ONGOING_PURCHASE))
            if(savedState) {
                drones = savedState.drones
                equipment = savedState.equipment
            }
            
            res = await InsuranceApi.loadOffers(startTime, state.maximumUasInAir, state.coverageType, drones, equipment,
                 state.operators, state.personalInjuryCoverage, state.medicalExpense, state.worldWideCoverage, state.firstGuidance, state.passengersCoverage,
                 state.airCrewCoverage)
            
        }
            
        if(res.ok) {
            dispatch({type: 'OFFERS_LOADED', monthlyOffers: res.monthlyOffers.insuranceOptions, annualOffers: res.annualOffers.insuranceOptions})
        }
        else {
            EventEmitterService.dispatch(EventKey.ShowError, res)
        }
    }

    useEffect(() => {
        !SessionService.isBroker() && loadOffers()

        return function cleanup() {
            //TODO: Deleting the local storage only for broker (?)
            if(SessionService.isBroker())
                StorageService.removeItem(OFFERS_HISTORY_KEY)
        }

    }, [])

    useEffect(() => {
        let id = EventEmitterService.subscribe(EventKey.HULL_ITEM_CHANGED, () => {
            loadOffers()
        })

        let id2 = EventEmitterService.subscribe(EventKey.USER_LOGGED_IN, () => {
            loadOffers()
        })

        let id3 = EventEmitterService.subscribe(EventKey.FLOW_PROFILE_FINISH, () => {
            // In case State has changed so offers will reflect taxes
            loadOffers()
        })

        let id4 = EventEmitterService.subscribe(EventKey.SESSION_USER_LOGOUT, () => {
            dispatch({type: 'RESET'})
        })

        return function cleanup () {
            EventEmitterService.unsubscribe(EventKey.HULL_ITEM_CHANGED, id)
            EventEmitterService.unsubscribe(EventKey.USER_LOGGED_IN, id2)
            EventEmitterService.unsubscribe(EventKey.FLOW_PROFILE_FINISH, id3)
            EventEmitterService.unsubscribe(EventKey.SESSION_USER_LOGOUT, id4)
        }
    }, [state])

    useEffect(() => {
        if(state != defaultState)
            loadOffers()
    }, [state.maximumUasInAir, state.coverageType, state.operators,
         state.subscriptionStartTime, state.personalInjuryCoverage, state.medicalExpense, state.worldWideCoverage,
         state.specialPrice, state.passengersCoverage, state.airCrewCoverage, state.firstGuidance])

    useEffect(() => {
        // Reset Starting date after successfull purchase
        let id = EventEmitterService.subscribe(EventKey.FLOW_INSURANCE_PURCHASED_SUCCESS, () => {
            changeStartingDate(NEW_STARTING_DATE);
        })

        return function cleanup () {
            EventEmitterService.unsubscribe(EventKey.FLOW_INSURANCE_PURCHASED_SUCCESS, id)
        }
    }, [])

    const changeStartingDate = (startingDate: Date) => {
        dispatch({type: 'CHANGE_STARTING_DATE', startingDate: startingDate})
    }

    const changeMaximumUAS = (maximumUasInAir) => {
        dispatch({type:'MAXIMUM_UAS_CHANGE', maximumUasInAir: maximumUasInAir})
    }

    const changePersonalInjury = (value) => {
        dispatch({type:'PERSONAL_INJURY_CHANGE', value: value})
    }

    const changeMedicalExpenseLimit = (value) => {
        dispatch({type:'MEDICAL_EXPENSE_LIMIT_CHANGE', value: value})
    }

    const changeCoverageType = (type) => {
        dispatch({type: 'SET_COVERAGE_TYPE', data: type});
    }

    const loadQuote = (quote) => {
        dispatch({type: 'LOAD_QUOTE', data: quote})
    }

    const flipSpecialPrice = () => {
        if(state.specialPrice.enabled == "yes")
            dispatch({type: OFFERS_ACTIOS.SET_SPECIAL_PRICE, data: {enabled: 'no',
             custom_premium: state.specialPrice.custom_premium,
             custom_premium_token: state.specialPrice.custom_premium_token,
            }})
        else
            dispatch({type: OFFERS_ACTIOS.SET_SPECIAL_PRICE, data: {enabled: 'yes',
             custom_premium: state.specialPrice.custom_premium,
             custom_premium_token: state.specialPrice.custom_premium_token,
            }})
    }

    const setSpecialPrice = (premium, premiumToken) => {
        dispatch({type: OFFERS_ACTIOS.SET_SPECIAL_PRICE, data: {enabled: 'yes',
         custom_premium: premium,
         custom_premium_token: premiumToken}})
    }

    const flipWorldWideCoverage = (description) => {
        if(state.worldWideCoverage.territorialLimits == WorldWideOption.Default)
            dispatch({type: 'SET_WORLD_WIDE_COVERAGE_LIMITS', data: {limits: WorldWideOption.Worldwide, desc: description }})
        else
            dispatch({type: 'SET_WORLD_WIDE_COVERAGE_LIMITS', data: {limits: WorldWideOption.Default, desc: description }})
    }

    return (<BuyingInsuranceContextStore.Provider value={{ ...state, dispatch, setSpecialPrice, flipSpecialPrice, flipWorldWideCoverage, changeMedicalExpenseLimit, changeCoverageType, changePersonalInjury, changeMaximumUAS, changeStartingDate, loadQuote }}>
            {props.children}
        </BuyingInsuranceContextStore.Provider>)   
}

export default BuyingInsuranceContext



