import { Container } from '@material-ui/core'
import React from 'react'


export const PrivacyPolicy = () => {

    return (<Container>
<p class="p1"><h1><strong>Skywatch Privacy Policy</strong></h1></p>
<p class="p2">&nbsp;</p>
<p class="p3"><strong>Introduction</strong></p>
<p class="p4">We, at Skywatch Ltd. (&ldquo;<strong>we</strong>&rdquo; or the &ldquo;<strong>Company</strong>"), are committed to protect the privacy of:</p>
<ol class="ol1">
<li class="li5">our customers and business partners (&ldquo;<strong>Customer(s)</strong>&rdquo;), who are licensed to access or use services offered by the Company (collectively with the Website: the &ldquo;<strong>Services</strong>&rdquo;); and</li>
<li class="li4">any individual who is authorized by our Customers to use the Services (<strong>&ldquo;User(s)</strong>").</li>
</ol>
<p class="p4">Please note that the term &ldquo;<span class="s1"><strong>you</strong></span>&rdquo; shall refer to website&rsquo;s visitors, Customers and Users, as applicable.</p>
<p class="p4">Our privacy policy ("<strong>Privacy Policy</strong>") will help you understand what types of information we collect through the Services, how the Company uses it, and what choices you have. We encourage you to read this Privacy Policy carefully and use it to make informed decisions. By using our Services, you agree to the terms of this Privacy Policy and your continued use of the Services constitutes your ongoing agreement to it.</p>
<table class="t1" cellspacing="0" cellpadding="0">
<tbody>
<tr>
<td class="td1" colspan="2" valign="middle">
<p class="p4">In this Privacy Policy you will read about:</p>
</td>
</tr>
<tr>
<td class="td2" valign="middle">
<ul class="ul1">
<li class="li5"><strong>What types of information</strong> we collect</li>
<li class="li5"><strong>Cookies </strong></li>
<li class="li5"><strong>How we use</strong> the information we collect</li>
<li class="li5"><strong>With whom we share</strong> the information we collect</li>
<li class="li5"><strong>Transferring </strong>of the information</li>
<li class="li5">Your<strong> privacy rights</strong></li>
<li class="li5"><strong>For how long we retain</strong> the information we collect</li>
<li class="li5"><strong>How do we safeguard</strong> your information</li>
<li class="li5"><strong>Marketing</strong></li>
<li class="li5"><strong>Minors</strong></li>
<li class="li5">How to <strong>contact us</strong></li>
</ul>
</td>
<td class="td3" valign="middle">
<p class="p7">&nbsp;</p>
</td>
</tr>
</tbody>
</table>
<p class="p3"><strong>What information do we collect?</strong></p>
<p class="p4">We and the insurer may collect the following types of information from or about you:</p>
<ul class="ul2">
<li class="li5"><strong>Personal Information</strong></li>
</ul>
<p class="p9">&nbsp;</p>
<p class="p10">Personal Information is individually identifiable information, namely information that identifies an individual or may with reasonable effort identify an individual. We gather the following types of Personal Information:</p>
<ul class="ul2">
<ul class="ul2">
<li class="li5"><strong>Registration details</strong>: When registering to our Services, we may collect certain information that can be used to identify you (as the Customer&rsquo;s point of contact&nbsp;or as a User) such as: name, email address, postal address, phone number<span class="s4">, </span>user IDs, licenses and permissions and other relevant details which are relevant to the Customer.</li>
<li class="li5"><strong>Customer&rsquo;s payment information</strong>: In order for you to enjoy all of the functions which we offer, we collect payment information from you, including your credit card number and bank account details. Please note that your billing information, to the extent provided by you, is forwarded directly to a third party billing or clearing company engaged by us, and is not kept by us.</li>
<li class="li5"><strong>Voluntary information</strong>: We also collect information which you provide us voluntarily. For example, when you respond to communications from us, ask for our support, communicate with us via email or share additional information about yourself through your use of the Services.</li>
<li class="li5"><strong>Device information</strong>: We also collect specific types of connection details and information with regard to your device, software or hardware that may identify you, such as: online identifiers, device&rsquo;s unique identifiers (e.g. UDID, MAC address), IP address, and geolocation.</li>
<li class="li5"><strong>Unmanned Aircraft System Log Files</strong>: We may receive, store and process log files from your unmanned aircraft system which you registered to the Services, including flight history and experience as a drone operator.</li>
<li class="li5"><strong>Information from connected accounts</strong>: We collect information which is available via accounts for which you provide us with login information and which is relevant to our Services. For example, we may collect flight data in order to provide you with insurance offerings.</li>
</ul>
</ul>
<p class="p11">&nbsp;</p>
<ul class="ul2">
<li class="li5"><strong>Non-personal Information</strong></li>
<p class="p10">Non-personal Information is non-identifiable information that, when taken alone, cannot be used to identify or contact you. As such, we are not aware of the identity of the user from which the Non-personal Information was collected.</p>
</ul>
<p class="p9">&nbsp;</p>
<ul class="ul2">
<li class="li5"><strong>Analytics Data</strong>: Our servers automatically record certain information regarding the use of the Service. Such data may include information such as the website that a User was visiting before accessing to our Webpage and other statistics related to our Services.</li>

<p class="p12">We analyze this information to improve and enhance the Services by expanding their features and functionality. Such data is also used for troubleshooting errors and bugs as well as for research and analytics purposes about your use of the Services.</p>
<p class="p12">For avoidance of doubt, if we combine Personal Information with Non-personal Information (e.g. analytics data), the combined information will be treated as Personal Information as long as it remains combined.</p>
</ul>
<ul class="ul2">
<ul class="ul2">
<li class="li5"><strong>Anonymous information</strong>: We may anonymize or de-identify the information collected by the Services or via other means so that the information cannot, on its own, personally identify you. Our use and disclosure of such aggregated or de-identified information is not subject to any restrictions under this Privacy Policy, and we may disclose it to others without limitation and for any purpose.</li>
</ul>
</ul>
<p class="p9">&nbsp;</p>
<ul class="ul2">
<li class="li5"><strong>User Data</strong></li>
<p class="p10">We collect and analyze Customer&rsquo;s activity on the Service and other data that is provided to us by Users (e.g. "<strong>User Data</strong>"). Such User Data is processed by us on our cloud computing servers.&nbsp; Please note that such User Data may include Personal Information. If you (as a Customer or a User) submit to us or to third-parties acting on our behalf any Personal Information relating to other individuals (e.g. employees, end-users, etc.) in connection with your use of the Service, you represent that you have the authority to do so and to permit us to use the information in accordance with this Privacy Policy.&nbsp;</p>
</ul>
<p class="p13">&nbsp;</p>

<p class="p14">If you have any reason to believe that our Services are misused, in any way, please don't hesitate to inform us at: privacy@skywatch.ai.</p>
<p class="p13">&nbsp;</p>
<p class="p3"><strong>Cookies and Pixels</strong></p>
<p class="p4">We and our partners (e.g. third party service providers, third party analytic companies, etc.) may use cookies and pixels, including when you access the Services.</p>
<p class="p4">A "cookie" is a small piece of information that a website assigns to your device while you are viewing the website. Cookies are very helpful and can be used for various different purposes. These purposes include allowing you to navigate between pages of the website efficiently, enable automatic activation of certain features, remembering your preferences and making the interaction between you and the Services quicker and easier. Cookies are also used to help ensure that the advertisements you see are relevant to you and your interests and to compile statistical data on your use of the Services.<span class="Apple-converted-space">&nbsp; </span></p>
<p class="p4">We may use the following types of cookies:</p>
<ul class="ul2">
<ul class="ul2">
<li class="li5"><strong>'Session cookies'</strong> which are stored temporarily during a browsing session in order to allow normal use of the system and are deleted from your device when the browser is closed;</li>
<li class="li5"><strong>'Persistent cookies</strong> which are saved on your computer for a fixed period and are not deleted when the browser is closed. Such cookies are used where we need to know who you are for repeat visits, to store your preferences for the next log-in and to improve our users&rsquo; experience of the Services;</li>
<li class="li5"><strong>'Third party cookies'</strong> which are set by other websites or services that run content on the page you are viewing, such as by third party analytic companies who monitor and analyze your web access.</li>
</ul>
</ul>
<p class="p11">&nbsp;</p>
<p class="p4">By accessing and using the Services, you consent to the placement of cookies and pixels on your device in accordance with this Privacy Policy.</p>
<p class="p4">You may remove the cookies by following the instructions of your device preferences; however, if you choose to disable cookies, some features of the Services may not operate properly and your online experience may be limited.</p>
<p class="p3"><strong>How we use the information we collect</strong></p>
<p class="p15">In addition to the purposes listed herein, the information we collect, which may include your Personal Information, is used for the following purposes:</p>
<ul class="ul2">
<li class="li5">To <strong>set up Customers' accounts</strong> and process their related billing information;</li>
<li class="li5">To identify and <strong>authenticate Users&rsquo; access</strong> to our Services;</li>
<li class="li5">To provide you with the <strong>information and services that you request</strong> from us, including accessing real-time mapping data and static geo-located data;</li>
<li class="li5">To <strong>process your insurance transactions</strong> (including claims) and policy requests to enable us to quote insurance for you;</li>
<li class="li5">To <strong>price insurance</strong> and identify different pricing models that are tailored to your profile and drone usage;</li>
<li class="li5">To <strong>support and troubleshoot</strong> our Services and to respond to queries;</li>
<li class="li5">To <strong>obtain Users&rsquo; feedback</strong> with regard to the Services;</li>
<li class="li5">To improve and <strong>customize our Services</strong> to Customer&rsquo;s needs and interests;</li>
<li class="li5">To <strong>empower our learning system</strong>, which performs statistical analysis on all the data and metadata which is collected through our Services;</li>
<li class="li16">To <strong>communicate with you</strong> and to keep you informed of our latest updates and newsletters;</li>
<li class="li4">To <strong>investigate violations and enforce our policies</strong>, and as required by law, regulation or other governmental authority, or to comply with a subpoena or similar legal process or respond to a government request.</li>
</ul>
<p class="p8">&nbsp;</p>
<p class="p3"><strong>With whom we share the information we collect</strong></p>
<p class="p15">We do not rent or sell any Personal Information. We may disclose Personal Information to other trusted third party service providers or partners for the following purposes:</p>
<ul class="ul2">
<li class="li5">To our insurance underwriting partners, the insurer and distribution partners, for providing insurance, and for related purposes, such as processing insurance claims, assessing risk associated with certain policies, etc.;</li>
</ul>
<p class="p9">&nbsp;</p>
<ul class="ul2">
<li class="li15">Assisting us with our business operations (e.g.&nbsp;processing and analyzing User Data, processing payments etc.);</li>
<li class="li15">Storage (e.g. cloud computing service providers);</li>
<li class="li15">Research and analytics that will enable us to improve our Product and Services;</li>
<li class="li15">If we have a good faith belief that disclosure of such information is helpful or reasonably necessary to: (i) comply with any applicable law, regulation, legal process or governmental request; (ii) enforce our policies (including our agreements), including investigations of potential violations thereof; (iii) investigate, detect, prevent, or take action regarding illegal activities or other wrongdoing, suspected fraud or security issues; (iv) to establish or exercise our rights to defend against legal claims; (v) prevent harm to the rights, property or safety of us, our users, yourself or any third party; or (vi) for the purpose of collaborating with law enforcement agencies or in case we find it necessary in order to enforce intellectual property or other legal rights.</li>
</ul>
<p class="p13">&nbsp;</p>
<p class="p15">In addition, we may transfer or disclose Personal Information to our subsidiaries and other affiliated companies.</p>
<p class="p3"><strong>International transfer</strong></p>
<p class="p15">Since we operate globally, it may be necessary to transfer data, including Personal Information, to countries outside of Israel.&nbsp;The data protection and other laws of these countries may&nbsp;not be as comprehensive as those in Israel &minus; in these instances we will take steps to ensure that a similar level of protection is given to Personal Information, including through adequate contractual measures.&nbsp;For example, we may transfer information to the United States in order to assist us with our business operations (e.g.&nbsp;processing and analyzing User Data). <strong>You hereby consent to the transfer of Personal Information and User Data to countries outside of Israel.</strong></p>
<p class="p3"><strong>Your privacy rights</strong></p>
<p class="p15">You may contact us at any time and request:</p>
<ul class="ul2">
<li class="li15">To access, delete, change or update any Personal Information relating to you (for example, if you believe that your Personal Information is incorrect, you may ask to have it corrected or deleted);</li>
<li class="li15">That we will cease any further use of your Personal Information (for example, you may ask that we will stop using or sharing your Personal Information with third parties).</li>
</ul>
<p class="p15">If you wish to raise a complaint on how we have handled your Personal Information, you can contact our Data Protection Officer who will investigate the matter. Our Data Protection Officer may be contacted at&nbsp;privacy@skywatch.ai.</p>
<p class="p15">If you are not satisfied with our response or believe we are collecting or processing your Personal Information not in accordance with the laws, you can complain to the applicable data protection authority.</p>
<p class="p17"><strong>For how long we retain the information we collect?</strong></p>
<p class="p15">We retain the information we collect from Customers and Users for as long as needed to provide our Services and to comply with our legal obligations, resolve disputes and enforce our agreements (unless we are instructed otherwise). We may rectify, replenish or remove incomplete or inaccurate information, at any time and at our own discretion.</p>
<p class="p17"><strong>How do we safeguard your information?</strong></p>
<p class="p15">We take great care in implementing and maintaining the security of our Services and your information. We employ industry standard procedures to ensure the confidentiality, integrity, and availability of our Customers&rsquo; systems and User Data, safety of your Personal Information, and prevent unauthorized use of any such information.</p>
<p class="p15">However, we cannot guarantee that hackers or unauthorized personnel will not gain access to information despite our efforts. You should note that by using our Services, your information will be transferred through third party infrastructures which are not under our control. No method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your Personal Information and User Data, we cannot guarantee its absolute security. If we discover that your information is accessed by unauthorized entities, we will seek to notify you in compliance with the applicable law.</p>
<p class="p15">If you feel that your privacy was treated not in accordance with this Privacy Policy, please contact us directly at privacy@skywatch.ai.</p>
<p class="p18"><strong>Marketing</strong></p>
<p class="p4">We may use your Personal Information, such as your full name, email address, etc. ourselves or by using our third-party subcontractors for the purpose of providing you with promotional materials, concerning our Services.</p>
<p class="p4">Out of respect to your right to privacy we provide you within such marketing materials with means to decline receiving further marketing offers from us. In addition, at any time, you may request to unsubscribe and discontinue receiving marketing offers by sending us a blank message with the word "remove" to&nbsp;privacy@skywatch.ai.</p>
<p class="p4">Please note that even if you unsubscribe from our marketing mailing list, we may continue to send you service-related updates and notifications.</p>
<p class="p18"><strong>Corporate transaction</strong></p>
<p class="p4">We may share information, including Personal Information, in the event of a corporate transaction (e.g. sale of a substantial part of our business, merger, consolidation or asset sale of an asset or transfer in the operation thereof) of the Company. In the event of the above, the acquiring company or transferee will respect the rights and obligations as described in this Privacy Policy.</p>
<p class="p18"><strong>Minors</strong></p>
<p class="p4">The Services are not designated to individuals under the age of 18. If you are under 16 you should not use the Services nor provide any Personal Information to us.</p>
<p class="p4">We reserve the right to access and verify any Personal Information which is collected from you by us. In the event that we become aware that an individual under the age of 18 shared any information, we will discard such information. If you have any reason to believe that a minor shared any information with us, please contact us at privacy@skywatch.ai.</p>
<p class="p18"><strong>Updates or amendments to the Privacy Policy</strong></p>
<p class="p4">We may revise this Privacy Policy from time to time, in our sole discretion, and the most current version will always be posted on our Website (as reflected in the "Last Revised" heading). We encourage you to review this Privacy Policy regularly for any changes. In case of material changes we may notify you through our Services or via email.</p>
<p class="p4">Your continued use of the Services, following the notification of such amendments on our Website, constitutes your acknowledgement and consent of such amendments to the Privacy Policy and your agreement to be bound by the terms of such amendments.</p>
<p class="p18"><strong>How to contact us</strong></p>
<p class="p4">If you have any general questions regarding the Services or the information that we collect about you and how we use it, please contact us at privacy@skywatch.ai.</p>
<p class="p18"><strong>Information about us</strong></p>
<p class="p19">The details of the Company are as follow:</p>
<p class="p19">Skywatch Technologies Ltd.</p>
<p class="p19">30 HaArba'a&nbsp;St.&nbsp;Tel Aviv, Israel</p>
<p class="p20">3921 Fabian way, Icon office, Palo Alto, CA</p>
</Container>)
}
