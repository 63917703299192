import { Container } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import './TermsAndConditions.scss'

export const  TermsAndConditions = () => {
    const history = useHistory()

    const privacyHref = history.createHref({pathname: '/privacy-policy'})

    return (<Container>
        <p class="p1"><strong><h1>TERMS OF SERVICE</h1></strong></p>
<p class="p2">These terms of service, together with any other agreements or terms incorporated by reference, including the Skywatch Privacy Policy (available at <a target="_blank" className="blue" href={privacyHref}>Privacy Policy</a>) (the "<strong>Terms</strong>") govern your use of the Services. These Terms constitute a binding and enforceable legal contract between Skywatch and You. By accepting these Terms electronically by clicking a box indicating your acceptance, or by using the Services, you agree to these Terms. If you are entering into these Terms on behalf of a company or another legal entity, you represent that you have the authority to bind such entity and its affiliates to these Terms, in which case the term "<strong>You</strong>" will refer to such entity and its affiliates. If the legal entity that you represent does not agree with these Terms, you must not accept these Terms or use the Services.</p>
<ol class="ol1">
<li class="li3"><span class="s3"><strong>Definitions </strong></span></li>
<ol class="ol1">
<li class="li3"><span class="s3">"<strong>Account</strong>" means an online account registered by you for the purpose of using the Services.</span></li>
<li class="li3"><span class="s3">"<strong>Connected Devices</strong>" means the unmanned aerial vehicles which You connected to the Platform and with respect to which Services are provided.</span></li>
<li class="li3"><span class="s3">"<strong>Content</strong>" means any files, data, material and information submitted, uploaded and stored by You through the Service or provided through an SNS Account.</span></li>
<li class="li4"><span class="s5">"<strong>Platform</strong>" means the Skywatch online solution for </span><span class="s3">planning, monitoring, and managing flights of Connected Devices and purchasing insurance for Connected Devices</span><span class="s5">. </span></li>
<li class="li3"><span class="s3">"<strong>Services</strong>" means any products, services, documentation, and software made available through the Platform. </span></li>
<li class="li3"><span class="s3">"<strong>User Data</strong>" means data relating to Your use of the Platform and Services in conjunction with the Connected Devices, including but not limited to information related to: </span></li>
<ol class="ol1">
<li class="li3"><span class="s3">Your contact and payment information, including email addresses and credit card or other payment remittance information, </span></li>
<li class="li3"><span class="s3">information regarding Your Connected Devices obtained by or provided through the Service, </span></li>
<li class="li3"><span class="s3">settings, preferences chosen, and resource usage, </span></li>
<li class="li3"><span class="s3">free text submitted by You. </span></li>
</ol>
<li class="li3"><span class="s3">"<strong>We</strong>", "<strong>Us</strong>", "<strong>Skywatch</strong>" or "<strong>Our</strong> Skywatch Technologies Ltd.</span></li>
<li class="li3"><span class="s3">"<strong>You</strong>" or "<strong>Customer</strong>" means the company or other legal entity and its affiliates for which you are accepting these Terms.</span></li>
</ol>
<li class="li3"><span class="s3"><strong>The Services</strong></span></li>
<ol class="ol1">
<li class="li3"><span class="s3"><strong>Registration</strong>. Following the initial registration of an Account you will have the ability to access the Platform and use the Services in conjunction with Connected Devices solely for the purpose of your business activity. We may update the Services from time to time, including adding or removing functions.</span></li>
<li class="li3"><span class="s3"><strong>Service Providers</strong>. We may use third parties in the operation of our Services or to perform any of our obligations (each a "<strong>Service Provider</strong>"), including using Service Providers for cloud infrastructure and hosting services. In order for our Service Providers to be able to provide you with certain aspects of the Services, we may share your data or Content with such Service Provider. Our agreements with Service Providers outline the appropriate use and handling of this information and prohibit the Service Provider from using any of your information for purposes unrelated to the Services.</span></li>
<li class="li3"><span class="s3"><strong>Children under 18</strong>.<span class="Apple-converted-space">&nbsp; </span>The Service is not directed at children. &nbsp;If you are a child, please do not use or attempt to register to use the Service.</span></li>
</ol>
<li class="li5"><strong>Charges</strong></li>
<ol class="ol1">
<li class="li4"><span class="s3"><strong>Insurance Purchase</strong>. In your use of the Service you may, from time to time, be provided with the opportunity to purchase insurance offerings from our third party associates through the Service. It is hereby clarified that such offerings are at the exclusive liability and responsibility of such third party associates, and you will have no right or claim against Skywatch in connection with such offerings, except a limited right to receive a refund of fees actually paid by you for such offerings, where such refund is available under the terms of purchase of the applicable offering. It is the responsibility of the insured to ensure that all sums insured and policy limits are adequate. Whilst we seek to assist in establishing and maintaining insured values and indemnity limits we cannot accept responsibility for their accuracy.&nbsp;</span></li>
<li class="li4"><span class="s3"><strong>Payment Policy</strong>. You authorize Skywatch, directly or through third parties, to make any inquiries we consider necessary to help verify your identity or prevent fraud.&nbsp; This may include asking you to provide a form of government identification (e.g., driver&rsquo;s license or passport), your date of birth and other information that is necessary to confirm ownership of your email address or payment method that you have added to your Account, such as a credit card, debit card or PayPal account (&ldquo;<strong>Payment Method</strong>&rdquo;). You must provide Skywatch with accurate information in relation to your Payment Method so that the purchase of insurance is paid for by you.&nbsp; Additionally, you authorize Skywatch to store your Payment Method and charge your Payment Method as outlined in these Terms. When you select and provide information to us pursuant to a Payment Method, you confirm that you are permitted to use that Payment Method and you authorize us and our designated payment processor to charge the full amount of the insurance to the Payment Method you designate for the purchase. All purchases are final and no refunds or credits will be provided. We will take steps to rectify any payment processing errors of which we become aware.&nbsp; These steps may include crediting or debiting (as appropriate) the same Payment Method used for the original payment by you, so that you end up paying the correct amount.</span></li>
</ol>
<li class="li3"><span class="s3"><strong>Registration and User Account</strong></span></li>
<ol class="ol1">
<li class="li3"><span class="s3"><strong>Establishing an Account</strong>. You must register and establish an Account in order to use our Services.</span> <span class="s3">Connected Devices may require registration or action on your part, to enable the activation of Services in relation to such Connected Devices. You may elect to have multiple Accounts; each Connected Device may only be registered to one Account at any given time<em>.</em></span></li>
<li class="li4"><span class="s3"><strong>SNS Account</strong>. You may register an Account by logging into your account with certain third-party social networking sites (&ldquo;<strong>SNS</strong>&rdquo;) including, but not limited to, Facebook (each such account, a &ldquo;<strong>Third-Party Account</strong>&rdquo;), as described below.&nbsp; As part of the functionality of the Services, you may link your Account with Third-Party Accounts, by either: (i) providing your Third-Party Account login information to Skywatch through the Service; or (ii) allowing Skywatch to access your Third-Party Account, as permitted under the applicable terms and conditions that govern your use of each Third-Party Account.&nbsp;By registering an Account through an SNS, You represent that you are entitled to disclose your Third-Party Account login information to Skywatch and/or grant Skywatch access to your Third-Party Account (including, but not limited to, for use for the purposes described herein), without breach by you of any of the terms and conditions that govern your use of the applicable Third-Party Account and without obligating Skywatch to pay any fees or making Skywatch subject to any usage limitations imposed by such third-party service providers.</span></li>
<li class="li3"><span class="s3"><strong>Account Information</strong>. You must safeguard and not disclose your Account username and password and you must supervise the use of such Account. You must provide us accurate and complete information in order to create an Account, including details of the Connected Devices that are linked to the Account. You agree to keep your Account information up to date and accurate, including with regard to Connected Devices. Any Services provided in connection with Connected Devices associated with Your Account will be charged to your Account. YOU ARE SOLELY AND FULLY RESPONSIBLE FOR MAINTAINING THE CONFIDENTIALITY OF THE ACCOUNT USERNAMES AND PASSWORDS. YOU ARE SOLELY AND FULLY RESPONSIBLE FOR ALL ACTIVITIES THAT OCCUR UNDER THE ACCOUNT. You must notify us immediately of any unauthorized use of your Account or any other breach of security.</span></li>
<li class="li4"><span class="s3"><strong>SNS Account</strong> <strong>Information</strong>. By granting Skywatch access to any Third-Party Accounts, you understand that Skywatch will access, make available and store (if applicable) any content that you have provided to and stored in your Third-Party Account (&ldquo;<strong>SNS Content</strong>&rdquo;) so that it is available on and through the Service via your Account.&nbsp; Depending on the Third-Party Accounts you choose and subject to the privacy settings that you have set in such Third-Party Accounts, personally identifiable information that you post to your Third-Party Accounts will be available on and through your Account on the Services.&nbsp; Please note that, if a Third-Party Account or associated service becomes unavailable or Skywatch&rsquo;s access to such Third-Party Account is terminated by the third-party service provider, then SNS Content will no longer be available on and through the Services.&nbsp; You have the ability to disable the connection between your Account and your Third-Party Accounts, at any time, on the App.&nbsp; Skywatch makes no effort to review any SNS Content for any purpose, including but not limited to for accuracy, legality or non-infringement and Skywatch is not responsible for any SNS Content.</span></li>
<li class="li3"><span class="s3"><strong>Deletion of Account</strong>. You may delete your Account at any time. Any Content and other information and data entered into the Services may be permanently deleted if you delete the Account, provided we may retain certain information as required to </span><span class="s6">comply with our legal obligations, resolve disputes and enforce our agreements (unless we are instructed otherwise)</span><span class="s3">. </span></li>
<li class="li3"><span class="s3"><strong>Use of Data</strong>. Skywatch may collect User Data, and You hereby grants Skywatch permission to collect User Data available on the Platform and to use such User Data to improve the Platform performance and functionality and improve services and support to Skywatch customers and for other business purposes including monitoring, statistical data gathering, diagnostics, comparative analyses, press and supplies utilization, complementary solutions usage, security and software integrity assurance, remote control and support and click performance tracking and billing.<span class="Apple-converted-space">&nbsp; </span>Skywatch may use Google Analytics in performing the above. Skywatch does not provide an opt-out option from the use of Google Analytics and therefore You should refrain from registering for the Service if You do not agree to the use of Google Analytics in connection with the User Data. Where applicable, User Data collected by Skywatch will be shared with You or any service provider who may provide You with supplementary services in connection with the Services or Connected Devices. Skywatch may further use User Data as set forth in its privacy policy referenced above. </span></li>
</ol>
<li class="li3"><span class="s3"><strong>Your Content </strong></span></li>
<ol class="ol1">
<li class="li3"><span class="s3"><strong>License to Content</strong>. You grant us a worldwide, non-exclusive license to host, copy and use your Content as required in order to provide You with the Services. Subject to this limited license we do not acquire any right in your Content and You or your licensors retain all rights and ownership to your Content. You warrant that You have full rights to provide to us any Content that You provide through the Services.</span> <span class="s3">We have policies in place to limit the access of our employees to Content. Where policies permit access to the Content, it is only for the purpose of providing the Services and supporting You in your use of the Services.</span></li>
<li class="li3"><span class="s3"><strong>Responsibility for Content</strong>. The Services are not intended to be used as storage, backup or archiving services. It is your responsibility to back up your Content and you are responsible for any lost or unrecoverable Content. </span></li>
</ol>
<li class="li3"><span class="s3"><strong>Insurance Products</strong></span><span class="s8">. </span><span class="s3">We may make our third party partner's insurance policies available for your purchase through the Service (the "<strong>Insurance Products</strong>"). Insurance Products are subject to the terms and conditions they contain, including exclusions, limitations, reductions in benefits and terms under which it may be continued in force or discontinued.&nbsp; Some Insurance Products may not be available in all states or territories. We will not be responsible for the Insurance Products or any act or omission of the third party that provides the Insurance Products, and we do not warrant any Insurance Products.</span></li>
<li class="li3"><span class="s3"><strong>Use&nbsp;Obligations and Restrictions</strong></span></li>
<ol class="ol1">
<li class="li3"><span class="s3"><strong>Obligations</strong>. You agree to do each of the following in connection with your use of the Services: (i) comply with all applicable laws, rules and regulations, including those regarding data privacy, intellectual property rights and export control, including any laws and regulations applicable to the operation of unmanned aircraft systems; (ii) pay the fees for the Services, if applicable, when due; (iii) use reasonable security precautions for providing access to the Services by your Users, customers or other individuals to whom You provide access.</span></li>
<li class="li3"><span class="s3"><strong>Restrictions</strong>. You must not misuse the Services. For example, you may not, whether by yourself or anyone on your behalf (a) sell, resell, or lease the Services or access or attempt to access the Services by any means other than the interface we provided or authorized; (b) circumvent any access or use restrictions put into place to prevent certain uses of the Services; (c) use the Services to store, share or transmit content which is unlawful, infringing, harmful or which violates any person&rsquo;s rights, including privacy rights and intellectual property rights; (d) attempt to disable, impair, or destroy the Services, or Platform; or (e) &nbsp;reverse engineer or decompile the Services, attempt to do so, or assist anyone in doing so. </span></li>
</ol>
<li class="li3"><span class="s3"><strong>Intellectual Property Rights</strong></span></li>
<ol class="ol1">
<li class="li3"><span class="s3"><strong>Retention of Rights</strong>. All rights not expressly granted to You under these Terms are reserved by Skywatch and its licensors. We and our licensors reserve all rights, title and interest to the Services, the Platform and any of their related intellectual property rights. The Terms do not convey to You an interest in or to Skywatch&rsquo;s intellectual property rights. Nothing in the Terms constitutes a waiver of Skywatch&rsquo;s Intellectual Property Rights under any law.</span></li>
<li class="li3"><span class="s3"><strong>Feedback</strong>. To the extent You provide us any feedback, comments or suggestions ("<strong>Feedback</strong>"), you grant us a royalty-free, fully paid up, worldwide, perpetual and irrevocable license to incorporate the Feedback into the Services or any of our current or future products or services. </span></li>
</ol>
<li class="li3"><span class="s3"><strong>Indemnification. </strong>You will indemnify, defend, and hold harmless Skywatch, its affiliates, resellers, employees and agents (the "<strong>Indemnified Parties</strong>") from and against all liabilities, damages, and costs (including reasonable attorneys' fees) arising out of any claim, demand, suit or proceeding by a third party alleging that your Content or your use of the Services infringes or misappropriates a third party&rsquo;s intellectual property rights or violates applicable law or that your use of the Services is in violation of these Terms.</span></li>
<li class="li3"><span class="s3"><strong>Disclaimers of Warranties</strong></span></li>
<ol class="ol1">
<li class="li3"><span class="s3">The Service may from time to time provide you with mapping and weather information. Such information is advisory only. We make no promises that your use of the mapping or whether information services provided by the Service are confirmation that you can fly an UAV in those areas or that the weather conditions ensure that it is safe to fly an UAV in those areas. We will not be liable to you for any losses or damages suffered by you resulting from your use of, or reliance on, the mapping or whether information services provided by the Service. It is your responsibility to ensure that you check the weather conditions and the location at the time you operate your UAV.</span></li>
<li class="li3"><span class="s3">THE SERVICES ARE PROVIDED ON AN "AS IS", AND "AS AVAILABLE" BASIS, AND SKYWATCH DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY, INCLUDING BUT NOT LIMITED TO RELIABILITY OF SERVICE, WARRANTIES OF NON-INFRINGEMENT OR IMPLIED WARRANTIES OF USE, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR USE. WE DISCLAIM ALL LIABILITY AND ANY OBLIGATIONS FOR ANY HARM OR DAMAGE CAUSED BY ANY THIRD PARTY HOSTING PROVIDERS.</span></li>
<li class="li3"><span class="s3">OTHER THAN AS EXPRESSLY STATED IN THE TERMS WE DO NOT WARRANT THAT THE SERVICES WILL BE SECURE, UNINTERRUPTED, WITHOUT ERROR, OR FREE OF DEFECTS.</span></li>
</ol>
<li class="li3"><span class="s3"><strong>Limitation of Liability</strong></span></li>
<ol class="ol1">
<li class="li3"><span class="s3">IN NO EVENT WILL SKYWATCH BE LIABLE FOR (I) INCIDENTAL, SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES OR (II) LOSS OF PROFITS OR REVENUE, LOSS OF DATA, BUSINESS INTERRUPTION, OR LOSS OF GOODWILL, IN EACH CASE ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE THE SERVICES, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT, OR OTHERWISE) AND EVEN IF SKYWATCH HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. </span></li>
<li class="li3"><span class="s3">IN NO EVENT WILL SKYWATCH&rsquo;S TOTAL LIABILITY TO YOU FOR ALL DAMAGES ARISING OUT OF OR RELATED TO THESE TERMS OR TO THE SERVICES EXCEED AN AMOUNT OF US$100.00. </span></li>
<li class="li3"><span class="s3">THESE LIMITATIONS WILL NOT APPLY TO BREACH OF THE PRIVACY POLICY OR TO THE EXTENT PROHIBITED BY LAW.</span></li>
</ol>
<li class="li3"><span class="s3"><strong>Term and Termination</strong></span></li>
<ol class="ol1">
<li class="li3"><span class="s3"><strong>Term</strong>. These Terms commence on the date you first accept them and will remain in effect until these Terms are terminated.</span></li>
<li class="li3"><span class="s3"><strong>Termination</strong>. You may stop using the Services at any time and you may delete your Account. We may suspend or terminate your access to the Services at any time at our discretion and without notice if You do not comply with these Terms. Upon termination of the Services to You, the Account will be terminated, and from the date of termination You will no longer be able to access your Account.</span></li>
<li class="li3"><span class="s3"><strong>Survival</strong>. The provisions of these Terms that, by their nature and content, must survive the termination of these Terms in order to achieve the fundamental purposes of these Terms shall so survive. Without limiting the generality of the foregoing, the Intellectual Property Rights, Disclaimers of Warranties, Limitation of Liability, Governing Law and Jurisdiction and General sections, will survive the termination or expiration of the Terms.</span></li>
</ol>
<li class="li3"><span class="s3"><strong>Governing Law and Jurisdiction</strong></span></li>
<p class="p6">These Terms are governed by the laws of the state of Israel excluding rules as to choice and conflicts of law and the courts in the state of Israel will have jurisdiction.</p>

<li class="li3"><span class="s3"><strong>General</strong></span></li>
<ol class="ol1">
<li class="li4"><span class="s5"><strong>Changes to Terms</strong>. Skywatch may change the Terms from time to time,</span><span class="s3"> and such change will become effective upon the date on which it is posted on the Platform. You are responsible for checking the website regularly for such changes. By continuing to access or use the Services you agree to be bound by the revised Terms. </span></li>
<li class="li3"><span class="s3"><strong>Severability</strong>. If any part of these Terms is deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed to be severable from the rest of these Terms and shall not affect the validity and enforceability of any of the remaining provisions of these Terms. In such cases, the part deemed invalid or unenforceable shall be construed in a manner consistent with applicable law to reflect, as closely as possible, the original intent of the parties.</span></li>
<li class="li3"><span class="s3"><strong>Waiver</strong>. No waiver by us of a breach of any of the provisions of terms of these Terms shall be construed as a waiver of any preceding or succeeding breach of any of the provisions of these Terms.</span></li>
<li class="li3"><span class="s3"><strong>Relationship</strong>. Nothing in these Terms shall be construed as creating any agency, partnership, trust arrangement, fiduciary relationship or any other form of joint enterprise between you and Skywatch. </span></li>
<li class="li3"><span class="s3"><strong>Entire Agreement</strong>. These Terms contain the entire agreement between Skywatch and You relating to your use of the Services and supersedes any and all prior agreements between Skywatch and You in relation to the same. You confirm that, in agreeing to accept these Terms, You have not relied on any representation except as has expressly been made by Skywatch in these Terms. </span></li>
<li class="li3"><span class="s3"><strong>Assignment</strong>. You may not assign your rights or delegate your obligations under these Terms without Skywatch&rsquo;s prior written consent. Any purported assignment contrary to this section will be null and void. Skywatch may assign its obligations hereunder among the various Skywatch entities within the SKYWATCH Inc. group, by </span><span class="s6">a change to the definition of Skywatch hereunder which change will become effective upon posting on the Platform</span><span class="s3">.</span></li>
<li class="li3"><span class="s3"><strong>No Third Party Rights</strong>. There are no third-party beneficiaries to these Terms. Without limiting this section, Users are not third-party beneficiaries to your rights under these Terms.</span></li>
</ol>
</ol>

<ol class="ol1">

</ol>

</Container>)
}