import React, { useReducer, useEffect } from "react"
import UserProfileModel from "../models/user-models/UserProfileModel"
import { AuthApi } from "../api/auth.api"
import { SessionService } from "../services/SessionService"
import { UserApi } from "../api/user.api"
import { EventEmitterService, EventKey } from "../services/EventEmitterService"
import StorageService, { StorageKey } from "../services/StorageService"
import brokerApi from "../api/broker.api"
import { awaitWithLoading } from "../services/utils"
import { useHistory } from "react-router-dom"
import BrokerConfigService from "../services/BrokerConfigService"
import discountsApi from "../api/discounts.api"
import { ONGOING_PURCHASE } from "./InsuracePurchaseContext"
import { OFFERS_HISTORY_KEY } from "./BuyingInsuranceContext"
import DataLayerService from "../services/DataLayerService"
import { ActiveInsuranceModel } from "../models/insurance-models/ActiveInsuranceModel";
import insuranceApi from "../api/insurance.api";


export const SESSION_ACTIONS = {
  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
  SET_USER_COMMERCIAL: 'SET_USER_COMMERCIAL',
  UPDATE_PROFILE_PICTURE: 'UPDATE_PROFILE_PICTURE',
  SET_BROKER: 'SET_BROKER',
  LOGOUT: 'LOGOUT',
  ACTIVE_INSURANCE_UPDATE: 'ACTIVE_INSURANCE_UPDATE',
  CC_SAVED: 'CC_SAVED'
}

const defaultState = {
    user: new UserProfileModel(),
    broker: new UserProfileModel(),
    activeInsurance: new ActiveInsuranceModel(),
    login: (userName, password) => { throw "Not Implemented"},
    logout: () => { throw "Not Implemented"},
    socialLogin: () => { throw "Not Implemented" },
    register: () => {throw "Not Implemented"},
    registerPasswordless: () => {throw "Not Implemented"},
    bindUserForBroker: (email) => {throw "Not implemented"},
    loadUser: () => {throw "Not implemented"},
}

const reducer = (state, action) => {
    console.log('SessionContext state', state, action)
    switch (action.type) {
      case SESSION_ACTIONS.SET_USER_COMMERCIAL:
        let user = Object.assign(state.user, { commercial: true })
        return {
          ...state,
          user: user
        }
      case SESSION_ACTIONS.CC_SAVED:
        let user_cc = Object.assign(state.user, { hasCreditCard: true})
        return {
          ...state,
          user: user_cc
        }
      case SESSION_ACTIONS.ACTIVE_INSURANCE_UPDATE: 
        return {...state, activeInsurance: action.data}
      case SESSION_ACTIONS.SET_BROKER: 
        return {...state, broker: action.data}
      case SESSION_ACTIONS.UPDATE_USER_PROFILE:
        return {
          ...state,
          user: action.data,
        };
      case SESSION_ACTIONS.UPDATE_PROFILE_PICTURE:
        return {...state, user: Object.assign({}, state.user, {profilePicture: action.url})}
      case SESSION_ACTIONS.LOGOUT:
        return {
          ...state,
          user: action.data,
          broker: action.data,
          activeInsurance: new ActiveInsuranceModel()
        }
      default: 
        return state;
    }
};

export const SessionContextStore = React.createContext(defaultState)

const SessionContext = (props) => {
    const [state, dispatch] = useReducer(reducer, defaultState)
    const history = useHistory()

    const loadActiveInsurance = async () => {
      let activeInsurance = await insuranceApi.getActiveInsurances();
      if (activeInsurance.ok){
        dispatch({type: SESSION_ACTIONS.ACTIVE_INSURANCE_UPDATE, data: activeInsurance.parsedData});
      }
    }

    useEffect(() => {
      let id3 = EventEmitterService.subscribe(EventKey.USER_UNAUTHORIZED, () => {
        logout()
        history.replace('/login')
      })
      return function cleanup () {
        EventEmitterService.unsubscribe(EventKey.USER_UNAUTHORIZED, id3)
      }
    }, [history])

    useEffect(() => {
      if(SessionService.isLoggedIn()) {
        if(SessionService.isBroker()) {
          brokerApi.getProfile().then(userRes => {
            dispatch({type: SESSION_ACTIONS.SET_BROKER, data: userRes.parsedData})
          }) 
        }
        UserApi.getProfile().then((userRes) => {
          if(userRes.ok) {
            dispatch({type: SESSION_ACTIONS.UPDATE_USER_PROFILE, data: userRes.parsedData})
          }
        })

        loadActiveInsurance();
      }

      let id = EventEmitterService.subscribe(EventKey.USER_ACCEPT_COMMERCIAL, () => {
        if(SessionService.isLoggedIn()) {
          UserApi.setIsCommercial()
        }
        else {
          StorageService.setItem(StorageKey.USER_IS_COMMERCIAL, true)
        }
        dispatch({type: SESSION_ACTIONS.SET_USER_COMMERCIAL})
      })

      let id2 = EventEmitterService.subscribe(EventKey.USER_PROFILE_IMAGE_UPDATED, (data) => {
        //TODO: dispatching this event to change the profile picture will make the user details forms to "reset" their state's
        // dispatch({type: SESSION_ACTIONS.UPDATE_PROFILE_PICTURE, url: data.url})
      })

      let id3 = EventEmitterService.subscribe(EventKey.FLOW_INSURANCE_PURCHASED_SUCCESS, (data) => {
        dispatch({type: SESSION_ACTIONS.ACTIVE_INSURANCE_UPDATE, data: data});
      })

      let id4 = EventEmitterService.subscribe(EventKey.FLOW_CC_FINISH, () => {
        dispatch({type: SESSION_ACTIONS.CC_SAVED});
      })

      let id5 = EventEmitterService.subscribe(EventKey.REDEEM_CODE_APPLIED, () => {
          loadUser()
      })

      return function cleanup () {
        EventEmitterService.unsubscribe(EventKey.USER_ACCEPT_COMMERCIAL, id)
        EventEmitterService.unsubscribe(EventKey.USER_PROFILE_IMAGE_UPDATED, id2)
        EventEmitterService.unsubscribe(EventKey.FLOW_INSURANCE_PURCHASED_SUCCESS, id3)
        EventEmitterService.unsubscribe(EventKey.FLOW_CC_FINISH, id4)
        EventEmitterService.unsubscribe(EventKey.REDEEM_CODE_APPLIED, id5)
      }
    }, [])

    const login = async (userName, password) => {
      let res = await AuthApi.login(userName, password)
      await afterLogin(userName, res, 'skywatch-email-password')
      return res
    }

    const socialLogin = async (email, serviceName, token) => {
      let res = await AuthApi.socialLogin(email, serviceName, token)

      if(res.ok) {
        if(!BrokerConfigService.isSkywatchBroker()) {
          BrokerConfigService.getRedeemCode().then( redeemCode => {
            discountsApi.useRedemptionCode(redeemCode)
          })
        }
      }

      afterLogin(email, res, serviceName)
      return res
    }

    const afterLogin = async (userName, res, serviceName) => {
      if(!res.ok) return;

      DataLayerService.pushToDataLayer({
        'event': 'successful login',
        'type': serviceName,
      })

      SessionService.setUserToken(userName, res.parsedData.token)

      loadActiveInsurance()
      return loadUser();
    }



    const loadUser = async () => {
      let userRes = await UserApi.getProfile()
      if(userRes.ok) {
        let user = userRes.parsedData

        if(StorageService.getItem(StorageKey.USER_IS_COMMERCIAL)) {
          await UserApi.setIsCommercial()
          user = Object.assign(user, { commercial: true })
          StorageService.removeItem(StorageKey.USER_IS_COMMERCIAL)
        }
        if(SessionService.isBroker()) {
          dispatch({type: SESSION_ACTIONS.SET_BROKER, data: user})
        }
        dispatch({type: SESSION_ACTIONS.UPDATE_USER_PROFILE, data: user})
        EventEmitterService.dispatch(EventKey.USER_LOGGED_IN)
      }
      return userRes
    }

    const register = async (email, password) => {
      let res = await AuthApi.register(email, password)
      if(res.ok) {

        DataLayerService.pushToDataLayer({
          'event': 'successful registration',
          'type': 'skywatch-email-password',
        })

        let loginRes = await login(email, password)
        if(!BrokerConfigService.isSkywatchBroker()) {
          BrokerConfigService.getRedeemCode().then( redeemCode => {
            discountsApi.useRedemptionCode(redeemCode)
          })
        }
        return loginRes
      }
      return res;
    }

    const registerPasswordless = async (form) => {
      let { email, identification_number } = form
      let res = await AuthApi.registerPasswordless(email, identification_number);
      if(res.ok) {

        DataLayerService.pushToDataLayer({
          'event': 'successful passwordless registration',
          'type': 'skywatch-email-passwordless',
        })

        let loginRes = await afterLogin(email, res, 'skywatch-email-passwordless')
        
        if(!BrokerConfigService.isSkywatchBroker()) {
          BrokerConfigService.getRedeemCode().then( redeemCode => {
            discountsApi.useRedemptionCode(redeemCode)
          })
        }
        return loginRes
      }
      return res;
    }

    const logout = () => {
      AuthApi.logout()
      SessionService.deleteSession()
      dispatch({ type: SESSION_ACTIONS.LOGOUT, data: new UserProfileModel() })
      EventEmitterService.dispatch(EventKey.SESSION_USER_LOGOUT)
      StorageService.removeItem(StorageKey.USER_IS_COMMERCIAL)
      //TODO: logging out from broker page makes the InsurancePurchaseContext not valid so the callback to delete state is never called
      StorageService.removeItem(ONGOING_PURCHASE)
      StorageService.removeItem(OFFERS_HISTORY_KEY)
      DataLayerService.userId = -1
    }

    const bindUserForBroker = async (email) => {
      EventEmitterService.dispatch(EventKey.ShowLoader)
      let res = await brokerApi.findUserOnBehalfBroker(email)
      if(res.ok) {
        SessionService.setBrokerOnBehalfToken(res.data.token)
        let userRes = await UserApi.getProfile()
        EventEmitterService.dispatch(EventKey.DismissLoader)
        if(userRes.ok) {
          EventEmitterService.dispatch(EventKey.USER_LOGGED_IN)
          dispatch({type: SESSION_ACTIONS.UPDATE_USER_PROFILE, data: userRes.parsedData})
        }
      }
      else {
        EventEmitterService.dispatch(EventKey.DismissLoader)
        EventEmitterService.dispatch(EventKey.ShowError, res)
      }
      return res;
    }

    return (<SessionContextStore.Provider value={{ ...state, dispatch, login, logout, loadUser, socialLogin, register, registerPasswordless, bindUserForBroker}}>
            {props.children}
        </SessionContextStore.Provider>)   
}

export default SessionContext

