import dayjs from "dayjs";

export const VERSION = "v 2.0.0";

export const SKYWATCH_API_VERSION = 3;

export const STATES = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AS": "American Samoa",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District Of Columbia",
    "FM": "Federated States Of Micronesia",
    "FL": "Florida",
    "GA": "Georgia",
    "GU": "Guam",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MH": "Marshall Islands",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "MP": "Northern Mariana Islands",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PW": "Palau",
    "PA": "Pennsylvania",
    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VI": "Virgin Islands",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
}

export const STATE_EXPIRY_TTL = 1000 * 60 * 60 * 24 * 2 // 2 days 

export const THREE_WEEKS_IN_MS = 1000*60*60*24*7*3

export const MAX_FUTURE_DATE_DAYS = 60

export const COUNTRIES = { US: 'US', CANADA: 'Canada', UK: 'UK' }

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const ENGLISH_NUMERIC_REGEX = /^[a-zA-Z0-9 \.,\[\]\-_/\\\(\){}\"\'\`]*$/

export const WEIRD_GENERATED_GUID = "00000000-0000-0000-0000-000000000000"

export const AIRCRAFT_REGISTRATION_PREFIX = "4X-"

export const NEW_STARTING_DATE = dayjs("2024-01-01", "YYYY-MM-DD").toDate()

export const INSURANCE_TERMS_PDF_URL = "https://skywatchapp-prod.s3.amazonaws.com/phoenix-aviation-prod/insuranceTerms2024.pdf"

export const USER_GUIDE_PDF_URL = "https://skywatchapp-prod.s3.amazonaws.com/phoenix-aviation-prod/userGuide2024.pdf"
