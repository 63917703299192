import ApiService, { HTTPMethod } from "./api.core";
import { Transformer } from "../models/Transformer";
import UserProfileModel from "../models/user-models/UserProfileModel";
import State from "../models/user-models/State";
import dayjs from "dayjs";
import Resizer from "../utilities/Resizer";
import { dataImageToFile, removeDuplicateAdditionalInsured } from '../services/utils'
import CreditCardModel from '../models/user-models/CreditCardModal'
import { AdditionalInsuredModel } from '../models/user-models/AdditionalInsuredModel'
import { FirebaseService, FirebaseConfigKey } from "../services/FirebaseService";
import DataLayerService from "../services/DataLayerService";

export class UserApi {

  static async getProfile() {
    let resArr = await Promise.all([ApiService.fetch('users/me'), UserApi.getProfilePicture()]) 
    let res = resArr[0]
    let imageRes = resArr[1]
    if(res.ok) {
        let user: UserProfileModel = Transformer.initializeFromData(new UserProfileModel(), res.data)
        let split = UserProfileModel.fullNameSplitter(res.data.full_name);
        user.firstName = split[0]
        user.lastName = split[1]
        user.profilePicture = imageRes
        res.parsedData = user

        //TODO: a better solution to Set user id for Google Tag Manager Service
        DataLayerService.userId = user.userId
    }
    return res
  }

  static async getStates() {
      let response = await ApiService.fetch("general/administrative_divisions")
        if (response.ok) {
          let states = [];
          for (let value in response.data.administrative_divisions) {
            let state = new State();
            state.name = value;
            state.code = response.data.administrative_divisions[value];
            states.push(state);
          }
          states.sort(function(a, b) {
            return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
          });
          response.parsedData = states;
        }
        return response
  }

  static async saveProfilePicture(profileImage) {
    let newProfileImage = await Resizer.imageFileResizer(profileImage, 250, 250, "JPEG", 100, 0, 'base64')
    let resizedImage = dataImageToFile(newProfileImage, profileImage.name)
    return ApiService.uploadFile("users/me/profile_image/upload", resizedImage);
  }

  static async saveAircrafts(aircrafts) {
    if (!aircrafts || aircrafts.length == 0){
      return {ok: false};
    }
    let aircraft = aircrafts[0];
    return await ApiService.fetch("users/me", HTTPMethod.PUT,{
      aircraft_registration_mark: aircraft.aircraft_registration_mark.toUpperCase(),
      aircraft_make_and_type: aircraft.aircraft_make_and_type.toUpperCase(),
      aircraft_year_of_manufacture: aircraft.aircraft_year_of_manufacture,
      max_number_of_passengers: aircraft.max_number_of_passengers,
    })
  }

  static async saveProfile(profile) {
    let res = await ApiService.fetch("users/me", HTTPMethod.POST, {
      full_name: UserProfileModel.fullNameCreator(profile.user_info.fname, profile.user_info.lname).toUpperCase(),
      company: profile.user_info.company_name,
      address1: profile.address.address_line_1.toUpperCase(),
      address2: profile.address.address_line_2,
      city: profile.address.city.toUpperCase(),
      state: 'IL', //profile.address.state,
      zip_code: profile.address.zipcode,
      commercial: profile.insurance_history.is_commercial == "yes",
      date_of_birth: "2000-02-02",
      drone_loss: profile.insurance_history.had_claim == "yes",
      drone_loss_description: profile.insurance_history.had_claim == "yes" ? profile.insurance_history.drone_loss_description : "",
      phone_number: profile.user_info.phone_number,
      identification_number: profile.user_info.identification_number,
    });
    if(res.ok)
      return UserApi.getProfile();
    else
      return res;
  }

  static async getProfilePicture() {
    let res = await ApiService.fetch('users/me/profile_image');
    if(res.ok)
      return res.data.image_url;
    else 
      return ""
  }

  static async updateCreditCard(token, expirationMonth, expirationYear, lastFourDigits) {
    return await ApiService.fetch("creditcards/me", HTTPMethod.POST, 
              {token: token, 
                expiration_month: expirationMonth, 
                expiration_year: expirationYear, 
                last_four_digits: lastFourDigits});
  }

  static async getCreditCardInfo() {
    let res = await ApiService.fetch("creditcards/me");
    console.log(res)
    if (res.ok) {
      let creditCardModel = new CreditCardModel();
      creditCardModel.lastFourDigits = res.data.last_four_digits;
      creditCardModel.expMonth = res.data.expiration_month.toString();
      creditCardModel.expYear = res.data.expiration_year.toString();
      creditCardModel.brand = res.data.brand ? res.data.brand.toLowerCase() : 'visa'
      res.parsedData = creditCardModel;
    }
    return res;
  }

  static async setIsCommercial() {
    return await ApiService.fetch('users/me', HTTPMethod.PATCH, [{
      "value": true,
      "path": "/commercial",
      "op": "replace"
    }])
  }

  static async getAdditionalInsureds() {
    let res = await ApiService.fetch("users/me/additionalInsured");
    if (res.ok && res.data){
      res.parsedData = removeDuplicateAdditionalInsured(res.data.sort((a, b) => b.id-a.id));
    }
    return res; 
  }

  

}