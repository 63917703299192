import React, { Suspense, lazy } from 'react';
import { useHistory } from 'react-router-dom';
import './SkyCookiesConsent.scss';
const CookieConsent = lazy (() => import("react-cookie-consent"));

export const SkyCookiesConsent = () => {

    const history = useHistory()

    const privacyHref = history.createHref({pathname: '/privacy-policy'})

    return (
        <Suspense fallback={<div />}>
         <CookieConsent
            cookieName="newskywatchcookieconset"
            containerClasses="cookiecontainer"
            buttonText="Got it!"
            buttonClasses="cookiebtn"
            location="bottom">
            <div className="text-small">We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies. <a className="white" href={privacyHref} rel="noopener noreferrer" target="_blank">More info</a></div>
        </CookieConsent>
    </Suspense>)
}